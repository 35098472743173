import React from 'react';
import forest from '../../assets/forestgreen.png';
import './intro.css';
import { lang } from "../../i18n/lang";

const Intro = () => (
  <div className="biomaderas__intro section__padding" id="biomaderas">
    <div className="biomaderas__intro-image">
      <img src={forest} alt="intro" />
    </div>
    <div className="biomaderas__intro-content">
      <h4>{lang.Intro}</h4>
      <h1 className="gradient__text">{lang.Intro2} <br /> {lang.Intro3}</h1>
      <p>{lang.Intro4}</p>
      <h4>{lang.Intro5}</h4>
      {lang.other}
    </div>
  </div>
);

export default Intro;