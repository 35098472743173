import React from 'react';
//import biomaderasLogo from '../../logo.svg';
import './footer.css';

import { lang } from "../../i18n/lang";

const Footer = () => (
  <div className="biomaderas__footer section__padding">

    <div className="biomaderas__footer-links">
      <div className="biomaderas__footer-links_logo">
       
        <p>Pital, San Carlos, Alajuela, Costa Rica<br /> All Rights Reserved</p>
      </div>
      <div className="biomaderas__footer-links_div">
        <h4>{lang.Links}</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div>
      <div className="biomaderas__footer-links_div">
        <h4>{lang.Company}</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="biomaderas__footer-links_div">
        <h4>{lang.GetInTouch}</h4>
        <p>Pital, San Carlos, Alajuela, Costa Rica</p>
        <p>Phone Number</p>
        <p>info@biomaderasfinasdelsur.com</p>
      </div>
    </div>

    <div className="biomaderas__footer-copyright">
      <p>@2023 Biomaderas. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;