import React from "react";
import "./about.css";
import forest from '../../assets/maderas.png';
import { lang } from "../../i18n/lang";

const About = () => (
  <div className="biomaderas__about section__padding" id="about">
    <div className="biomaderas__about-content">
      <div className="biomaderas__about-content1">
        <h1>{lang.About1}</h1>
        <br />
        <div className="biomaderas__about-content1-text">
        {lang.About2}
          <br />
        </div>
        <h2>
        {lang.About3}
        </h2>
      </div>
      <div className="biomaderas__about-content2">
        <img src={forest} alt="intro" />
      </div>
    </div>
  </div>
);

export default About;
