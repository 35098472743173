import React from "react";
//import people from '../../assets/people.png';
import maderas from "../../assets/maderas.png";
import trees from "../../assets/treesblur.jpg";
import "./header.css";
import { lang } from "../../i18n/lang";

const Header = () => (
  <div className="biomaderas__header section__padding"  style={{
    backgroundImage: `url(${trees})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }} id="home">
    <div className="biomaderas__header-content">
      <h1 className="intro__text">{lang.Header}</h1>
      <p>{lang.Header2}</p>
    </div>
  </div>
);

export default Header;
