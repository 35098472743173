import React, { useContext } from "react";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Header, Intro, Contact, Footer, Foundus, Gallery, Slide, TextSlide, About } from "./containers";
import {Navbar } from "./components";
import { LangContext } from "../src/context-api/LangProvider";

import young from './../src/assets/young.jpg'
import young1 from './../src/assets/young2.jpg'
import young2 from './../src/assets/young3.jpg'
import logs from './../src/assets/logs.jpg'
import logs2 from './../src/assets/logs2.jpg'
import planks from './../src/assets/planks.jpg'
import planks2 from './../src/assets/planks2.jpg'
import forest from './../src/assets/forest.jpg'

import "./App.css";
const App = () => {
  useContext(LangContext);
  return (
    <div className="App">
      <Navbar></Navbar>
        
        <Header></Header>
        <Intro></Intro>
        <About></About>
        <Gallery controles={true} autoplay={true} velocidad="5000" intervalo="5000">
            <Slide>
                <img src={young} alt=""/>
            </Slide>
            <Slide>
                <img src={young1} alt=""/>
            </Slide>
            <Slide>
                <img src={young2} alt=""/>
            </Slide>
            <Slide>
                <img src={logs} alt=""/>
            </Slide>
            <Slide>
                <img src={logs2} alt=""/>
            </Slide>
            <Slide>
                <img src={planks} alt=""/>
            </Slide>
            <Slide>
                <img src={planks2} alt=""/>
            </Slide>
        </Gallery>
        <Contact></Contact>
        <Foundus></Foundus>
      
    

      <Footer></Footer>
    </div>
  );
};

export default App;
