import React from "react";
import ReactDom from "react-dom/client";

import App from "./App";
import "./index.css";
import LangProvider from "./context-api/LangProvider";

const root = ReactDom.createRoot(document.getElementById("root"));
root.render(
  <LangProvider>
    <App />
  </LangProvider>
);
