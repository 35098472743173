import { createContext, useState } from 'react';
import { lang } from "../i18n/lang";

export const LangContext = createContext();

const LangProvider = (props) => {
    const [strLang, setStrLang] = useState(lang.getLanguage());

    const changeLang = () => {
        
        if(strLang === "es"){
            console.log("es")
            lang.setLanguage("en-US");
            setStrLang("en-US");
        }else{
            console.log("en")
            lang.setLanguage("es");
            setStrLang("es");
        }
    }

    return (
        <LangContext.Provider value={{changeLang, strLang}}>
        {props.children}
        </LangContext.Provider>
    );
};

export default LangProvider;