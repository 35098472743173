import React from 'react';
import './foundus.css';
import { lang } from "../../i18n/lang";
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet'

const Foundus = () => {
  
  return (
    
  <div id="map" className='foundus_style'>
      <h2 className="foundus_title">{lang['Whereyoucanfoundus?']}</h2>
      <div className="found_spacing"></div> {/*Este es tu espacio*/}
        <MapContainer className='map_container' center={[10.45, -84.28]} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[10.45, -84.28]}>
          <Popup>
          <a href="https://maps.app.goo.gl/gp8KbRMiRaSs8yMBA">Follow us with maps</a>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  )
}



export default Foundus
