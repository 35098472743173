import React, { useState , useContext, useEffect} from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../LogoNoBG.png';
import logo1 from '../../assets/CutLogoBG.jpg' ;
import './navbar.css';
import { lang } from "../../i18n/lang";
import { LangContext } from '../../context-api/LangProvider';



const Navbar = () => {

  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
        setColor (true)
      }
    else {
        setColor (false)
      }
  } 

  window.addEventListener('scroll', changeColor)

  let oldScrollY = 0;

  const [direction, setDirection] = useState('up');
  
  const controlDirection = () => {
      if(window.scrollY > oldScrollY) {
          setDirection('down');
      } else {
          setDirection('up');
      }
      oldScrollY = window.scrollY;
  }
  
  useEffect(() => {
      window.addEventListener('scroll', controlDirection);
      return () => {
          window.removeEventListener('scroll', controlDirection);
      };
  },[]);




  const [toggleMenu, setToggleMenu] = useState(false);
  const { changeLang } = useContext(LangContext);
  return (
    <div className={`biomaderas__navbar ${color ? 'biomaderas__navbar_header_bg' : ''} ${direction === 'down' ? 'biomaderas__navbar_header_bg_scroll' : ''}`}>
      <div className="biomaderas__navbar-links">
        <div className="biomaderas__navbar-links_logo">
        <img src={logo1} />
        </div>
        <div className="biomaderas__navbar-links_container">
          <p><a href="#home">{lang.Home}</a></p>
          <p><a href="#biomaderas">Biomaderas</a></p>
          <p><a href="#about">{lang.AboutUS}</a></p>
          <p><a href="#gallery">{lang.Gallery}</a></p>
          <p><a href="#contact">{lang.Contact}</a></p>
          <p><a href="#map">{lang['Whereyoucanfoundus?']}</a></p>
          <label className="tab" onClick={changeLang}>
          {lang.language === 'Español' ? (
            <img
              src={'https://cdn-icons-png.flaticon.com/512/206/206724.png'}
              alt="US"
              className="photo"
            />
          ) : (
            <img
              src={'https://cdn-icons-png.flaticon.com/512/206/206626.png'}
              alt="US"
              className="photo"
            />
          )}
        </label>
        </div>
      </div>
      <div className="biomaderas__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="green" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="green" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="biomaderas__navbar-menu_container scale-up-center">
          <div className="biomaderas__navbar-menu_container-links">
          <p><a href="#home">{lang.Home}</a></p>
          <p><a href="#biomaderas">Biomaderas</a></p>
          <p><a href="#about">{lang.AboutUS}</a></p>
          <p><a href="#gallery">{lang.Gallery}</a></p>
          <p><a href="#contact">{lang.Contact}</a></p>
          <p><a href="#map">{lang['Whereyoucanfoundus?']}</a></p>
            <label className="tab" onClick={changeLang}>
          {lang.language === 'Español' ? (
            <img
              src={'https://cdn-icons-png.flaticon.com/512/206/206724.png'}
              alt="US"
              className="photo"
            />
          ) : (
            <img
              src={'https://cdn-icons-png.flaticon.com/512/206/206626.png'}
              alt="US"
              className="photo"
            />
          )}
        </label>
          </div>

        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;